body {
  background-color: lightblue;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

li {
  margin: 20px 0;
}

.fa {
  margin: 40px;
  font-size: 30px;
}

td {
  vertical-align:top;
}

div.empty {
  max-width: 100px;
  margin: auto;
}

* {
  font-family: 'Questrial', sans-serif;
  color: black;
}